// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

// Initialize i18n with HttpBackend for dynamic loading
i18n
  .use(HttpBackend) // Use the HTTP backend for loading translations
  .use(initReactI18next) // Connect with React
  .init({
    backend: {
      // Path to load translation files from the public directory
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    lng: 'en', // Default language; will be overridden by UserStore
    fallbackLng: 'en',
    ns: ['translation'], // Default namespace
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    react: {
      useSuspense: true, // Enable Suspense for lazy loading
    },
    debug: false, // Set to true for debugging
  });

export default i18n;
