// src/store.js
import { makeAutoObservable, runInAction, autorun } from 'mobx';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import i18n from './i18n'; // Import the updated i18n configuration

class UserStore {
  user = null;
  accountType = null;
  companyName = '';
  companyLogo = '';
  linkId = null;
  InterviewLimitReached = false;
  onboardingPassed = 0;
  language = this.getInitialLanguage(); // Initialize language

  constructor() {
    makeAutoObservable(this, {
      user: true,
      accountType: true,
      companyName: true,
      companyLogo: true,
      linkId: true,
      InterviewLimitReached: true,
      onboardingPassed: true,
      language: true,
      setLanguage: true,
      // Other methods as actions if needed
    });

    this.loadUserFromLocalStorage();
    this.setupLanguageObserver();

    console.log('UserStore initialized with:', this);
  }

  // Method to get the initial language
  getInitialLanguage() {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      return storedLanguage;
    } else {
      const browserLanguage = navigator.language.split('-')[0];
      const supportedLanguages = ['en', 'pt', 'fr', 'sp', 'ru']; // Add all supported languages here
      if (supportedLanguages.includes(browserLanguage)) {
        return browserLanguage;
      } else {
        return 'en';
      }
    }
  }

  // Action to set the language
  setLanguage(lng) {
    runInAction(() => {
      this.language = lng;
    });
    localStorage.setItem('language', lng);
    console.log(`Language set to ${lng}`);
  }

  // Setup observer to react to language changes and update i18n
  setupLanguageObserver() {
    autorun(() => {
      const currentLanguage = this.language;
      if (currentLanguage && i18n.language !== currentLanguage) {
        i18n.changeLanguage(currentLanguage);
      }
    });
  }

  // Load user data from localStorage
  loadUserFromLocalStorage() {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      this.user = storedUser;
      this.accountType = localStorage.getItem('accountType');
      this.companyName = localStorage.getItem('companyName');
      this.companyLogo = localStorage.getItem('companyLogo');
      this.InterviewLimitReached = JSON.parse(
        localStorage.getItem('InterviewLimitReached')
      );
      this.onboardingPassed = JSON.parse(
        localStorage.getItem('onboardingPassed')
      );
      this.linkId = localStorage.getItem('linkId');
      this.language = localStorage.getItem('language') || this.language; // Load language
    }
  }

  // Save user data to localStorage
  saveUserToLocalStorage() {
    localStorage.setItem('user', JSON.stringify(this.user));
    localStorage.setItem('accountType', this.accountType);
    localStorage.setItem('companyName', this.companyName);
    localStorage.setItem('companyLogo', this.companyLogo);
    localStorage.setItem(
      'InterviewLimitReached',
      JSON.stringify(this.InterviewLimitReached)
    );
    localStorage.setItem(
      'onboardingPassed',
      JSON.stringify(this.onboardingPassed)
    );
    localStorage.setItem('linkId', this.linkId);
    localStorage.setItem('language', this.language); // Save language
  }

  // Clear user data from localStorage
  clearLocalStorage() {
    localStorage.removeItem('user');
    localStorage.removeItem('accountType');
    localStorage.removeItem('companyName');
    localStorage.removeItem('companyLogo');
    localStorage.removeItem('InterviewLimitReached');
    localStorage.removeItem('onboardingPassed');
    localStorage.removeItem('linkId');
    localStorage.removeItem('language'); // Remove language
  }

  // Reset user state
  resetUserState() {
    this.user = null;
    this.accountType = null;
    this.companyName = '';
    this.companyLogo = '';
    this.InterviewLimitReached = false;
    this.onboardingPassed = 0;
    this.language = 'en'; // Reset language to default if needed
  }

  // Initialize user data
  async initializeUser(authUser) {
    if (authUser && authUser.emailVerified) {
      const userId = authUser.uid;
      await this.fetchUserDetails(userId);
      await this.fetchInterviewLimitStatus(userId);
      runInAction(() => {
        this.user = authUser;
        this.saveUserToLocalStorage();
        console.log('User initialized:', this);
      });
    } else {
      this.logout();
    }
  }

  // Logout user
  logout() {
    runInAction(() => {
      this.resetUserState();
    });
    this.clearLocalStorage();
  }

  // Fetch user details from Firestore
  async fetchUserDetails(userId) {
    const db = getFirestore();
    const userDocRef = doc(db, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const data = userDocSnap.data();
      runInAction(() => {
        this.accountType = data?.accountType || null;
        this.companyName = data?.companyName || '';
        this.companyLogo = data?.companyLogo || '';
        this.onboardingPassed = data?.onboardingPassed || 0;
        this.language = data?.language || this.language; // Fetch language from Firebase
        this.saveUserToLocalStorage();
      });
    } else {
      console.error('User document does not exist');
      runInAction(() => {
        this.resetUserState();
      });
    }
  }

  // Fetch interview limit status from Firestore
  async fetchInterviewLimitStatus(userId) {
    const db = getFirestore();
    const subscriptionDocRef = doc(db, 'subscription_status', userId);
    const subscriptionDocSnap = await getDoc(subscriptionDocRef);

    if (subscriptionDocSnap.exists()) {
      runInAction(() => {
        this.InterviewLimitReached =
          subscriptionDocSnap.data().InterviewLimitReached || false;
        this.saveUserToLocalStorage();
      });
    } else {
      console.error('Subscription status document does not exist');
      runInAction(() => {
        this.InterviewLimitReached = false;
        this.saveUserToLocalStorage();
      });
    }
  }

  // Set Link ID
  setLinkId(linkId) {
    runInAction(() => {
      this.linkId = linkId;
    });
    this.saveUserToLocalStorage();
  }

  // Update company details
  updateCompanyDetails(companyName, companyLogo) {
    try {
      console.log('Attempting to update company details with:', {
        companyName,
        companyLogo,
      });
      console.log('Current UserStore instance:', this);
      runInAction(() => {
        if (!this) {
          throw new Error('UserStore instance is undefined.');
        }
        console.log('Setting companyName:', companyName);
        this.companyName = companyName;
        console.log('Setting companyLogo:', companyLogo);
        this.companyLogo = companyLogo;
        console.log('Saving to local storage with new values.');
        this.saveUserToLocalStorage();
        console.log('Company details updated successfully.');
      });
    } catch (error) {
      console.error('Error in updateCompanyDetails:', error);
    }
  }
}

const userStore = new UserStore();
export default userStore;
